export const environment: any = {
  "production": true,
  "API": "https://api.rushmypassport.com/api/",
  "client_url": "https://my.govworks.com",  
  "visas": false,
  "header": {
    "logo": "assets/svg/airside_logo.svg"
  },
  "support": {
    "phone": "8882458846",
    "email": "info@rushmypassport.com"
  },
  "login": {
    "enabled": false,
    "logo": "assets/svg/airside_logo.svg"
  },
  "terms": {
    "website_url": "https://www.rushmypassport.com",
    "website": "www.rushmypassport.com",
    "website_name": "RushMyPassport.com",
    "website_email": "info@rushmypassport.com"
  },
  "legal_links": {
    "terms": "/terms-of-use",
    "privacy": "/privacy-policy",
    "refund": "/refund-policy"
  },
  "source": {
    "domain": "mobilepassport",
    "main_website": "#",
    "promo_code": true,
    "name": "MobilePassport",
  },
  "footer": {
    "logo": "assets/svg/airside_logo_white.svg",
    "logo_link": "/",
    "copyright": "RushMyPassport.com, LLC"
  },
  "landing_pages": {
    "passport": {
      "enabled": true,
      "title": "Mobile Passport | Passport Expediting",
      "signin": "Sign in to our secure application portal.",
      "sign_in_links": ["visas"],
      "heading": "Passports With Mobile Passport.",
      "description": "Traveling soon and need a passport? Begin your passport application now through Mobile Passport."
    },
    "visa": {
      "enabled": true,
      "title": "Mobile Passport | Visa Expediting",
      "heading": "Traveling soon and need a travel visa?",
      "description": "Business visa, tourist visa, and everything in between. Our visa experts across the country handle your travel visa requests - quickly, securely, and trouble-free." 
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true,
  "seals": {},
  "summary_cta_icon": true,
  "livechat": {
    "enabled": true,
    "license_id": "1073051",
    "group": 14
  },
  "locations_page": {
    "enabled": false
  },
  "tags": {
    "analytics": "UA-2117379-6",
    "tag_manager": "GTM-WGHX3XS",
    "mouseflow": "c001d7f9-14fb-4d71-8567-4198d447257f",
    "ga4": ["G-720Z0BG9GX"]
  }
};